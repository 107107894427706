.pageHeader {
    background: var(--baseColor);
    align-self: center;
    display: flex;
    justify-content: center;
    border-radius: 1.5em;
    h1 {
        color: var(--textOnBaseColor);
        margin: .9em 0;
        // margin-top: .6em;
        vertical-align: middle;
        font-size: 1.5em;
        letter-spacing: .03em;
        // height: 100%;
    }
    p{
        color: var(--textOnBaseColor);
    }
}



