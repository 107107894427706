@import-normalize;
@import "vars";

@font-face {
  font-family: Ubuntu;
  src: url("../public/Ubuntu-Bold.ttf");
}
@font-face {
  font-family: UbuntuReg;
  src: url("../public/Ubuntu-Medium.ttf");
}
html,
body,
div,
h1 {
  margin: 0;
  padding: 0;
}

html {
  font-family: Ubuntu;
  font-size: 16pt;
  // font-family: Lato;
  color: var(--color3);
  line-height: 1.5em;
}

body {
  background: var(--backgroundColor);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > main {
    flex: 1 1 auto;
  }
}

h1 {
}

h2 {
  // font-family: Roboto Slab;
  color: var(--color1);
  font-size: 20pt;
  font-weight: normal;
  text-align: center;
}

p {
}
