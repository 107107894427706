.cookies {
    position: fixed;
    bottom: 0;
    left: 0;

    z-index: 10;

    width: 100vw;
    max-height: 33vh;
        height: 100%;

    pointer-events: none;
}

.window {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.inner {
    $sidePadding: 1em;
    position: relative;
    padding: 0 $sidePadding 1em;
    overflow: hidden;

    width: calc(100% - 2 * $sidePadding);

    background: white;
    border-top: 2px solid var(--color3);

    pointer-events: all;

    text-align: center;
    overflow: scroll;
}
